import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import range from 'lodash/range'
import {
  bannersProviderActions,
  selectBannerItems,
  selectBannersLoading
} from 'astra-core/containers/BannersProvider'

import { useInterval } from 'hooks'
import { CarouselApi } from 'shared/ui/Carousel'

import {
  StyledBannerControlsWrapper,
  StyledBannerControl,
  StyledBannersCarousel,
  StyledBannerWrapper
} from './Banners.styled'
import { Banner, BannerSkeleton } from './Banner'

const BANNERS_SCROLL_TIMEOUT = 5 * 1000

export const Banners: FC = memo(() => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(bannersProviderActions.fetchBanners())
  }, [dispatch])
  const carouselApi = useRef<CarouselApi>()
  const banners = useSelector(selectBannerItems)

  const loading = useSelector(selectBannersLoading)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleIndexChange = useCallback(
    (index: number) => setCurrentIndex(index),
    []
  )

  const scrollToNext = useCallback(() => {
    if (carouselApi.current) {
      if (currentIndex + 1 < banners.length) {
        setCurrentIndex((prev) => {
          carouselApi.current?.scrollTo(currentIndex + 1)
          return prev + 1
        })
      } else {
        setCurrentIndex((prev) => {
          carouselApi.current?.scrollTo(0)
          return prev
        })
      }
    }
  }, [banners.length, currentIndex])

  useInterval(scrollToNext, BANNERS_SCROLL_TIMEOUT)

  return (
    <StyledBannerWrapper>
      <StyledBannersCarousel
        onCarouselMounted={(api) => {
          carouselApi.current = api
        }}
        onIndexChange={handleIndexChange}
      >
        {loading !== false
          ? range(5).map((item) => <BannerSkeleton key={item} />)
          : banners.map((banner) => <Banner banner={banner} key={banner.id} />)}
      </StyledBannersCarousel>
      <StyledBannerControlsWrapper>
        {loading !== false
          ? range(5).map((item, index) => (
              <StyledBannerControl
                isActive={currentIndex === index}
                key={item}
              />
            ))
          : banners.map((banner, index) => (
              <StyledBannerControl
                isActive={currentIndex === index}
                key={banner.id}
              />
            ))}
      </StyledBannerControlsWrapper>
    </StyledBannerWrapper>
  )
})
