import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { LayoutTheme } from 'betweb-openapi-axios'

import { Carousel } from 'shared/ui/Carousel'

import { StyledBannerProps, StyledBannerControlProps } from './Banners.types'

export const StyledBannerWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 0.5rem 0;
`

export const StyledBannersCarousel = styled(Carousel)`
  padding: 0 1rem;
`
export const StyledBannersLink = styled(Link)``

export const StyledBanner = styled.div<StyledBannerProps>`
  background: ${(p) =>
      p.imageUrl ? `url(${p.imageUrl})` : p.theme.colors.custom.primary[4]}
    no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0.5rem;
  height: 144px;
  width: 370px;
`

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`
export const StyledBannerSkeleton = styled(StyledBanner)`
  background-position: center;
  background-size: cover;
  border-radius: 0.5rem;
  height: 125px;
  overflow: hidden;
  position: relative;
  width: 320px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const StyledBannerControlsWrapper = styled.div`
  column-gap: 0.25rem;
  display: grid;
  grid-auto-columns: 24px;
  grid-auto-flow: column;
  grid-template-rows: 2px;
  padding: 0 1rem;
`

export const StyledBannerControl = styled.div<StyledBannerControlProps>`
  background-color: ${(p) =>
    p.isActive
      ? p.theme.colors.default.primary[90]
      : p.theme.colors.default.primary[10]};
`
